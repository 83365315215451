import { axios } from '@/plugins/axios';

export const getBanks = (params) => axios.get('/banks', {params: params})
  .then(res => res.data);

export const getBankAccounts = (params) => axios.get('/bank-accounts', {params: params})
  .then(res => res.data);

export const createBankAccount = (params) => {
  return axios
    .post('/bank-accounts', params)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const deleteBankAccount = (id) => {
  return axios
    .delete(`/bank-accounts/${id}`)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};
