export class BankAccount {

  constructor(bankAccountObj) {
    if(typeof bankAccountObj === 'undefined' || bankAccountObj === null){
      bankAccountObj = {};
    }

    this.id         = bankAccountObj.id || null;
    this.bank_id    = bankAccountObj.bank_id || null;
    this.bank_name  = bankAccountObj.bank_name || null;
    this.currency   = bankAccountObj.currency || null;
    this.agency     = bankAccountObj.agency || null;
    this.account    = bankAccountObj.account || null;
    this.active     = bankAccountObj.active || null;

    this.created_at = new Date();

    if(typeof bankAccountObj.created_at !== "undefined"){
      this.created_at = new Date(bankAccountObj.created_at);
    }
  }

  static init(bankAccountObj) {
    return new BankAccount(bankAccountObj);
  }
}

