import { axios } from '@/plugins/axios';

export const getWithdrawals = (params) => axios.get('/withdrawals', {params: params})
  .then(res => res.data);

export const createWithdraw = (params) => {
  let obj = {
    bank_account_id: params.bank_account_id,
    amount: params.amount,
  };

  return axios
    .post('/withdrawals', obj)
    .then(res => {
      if (res.data) {
        return res.data;
      }
      return {};
    });
};

export const getWithdrawalsPayload = (params) => axios.get('/withdrawals/payload', {params: params})
  .then(res => res.data);