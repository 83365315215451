import {Partner} from "./Partner";
import {BankAccount} from "./BankAccount";

export class Withdraw {

  constructor(withdrawObj) {
    if(typeof withdrawObj === 'undefined' || withdrawObj === null){
      withdrawObj = {};
    }

    this.id           = withdrawObj.id || null;
    this.bank_account_id = withdrawObj.bank_account_id || null;
    this.partner_id   = withdrawObj.partner_id || null;
    this.description  = withdrawObj.description || null;
    this.amount       = withdrawObj.amount || null;
    this.tax          = withdrawObj.tax || null;
    this.currency     = withdrawObj.currency || null;
    this.status       = withdrawObj.status || null;
    this.recipe       = withdrawObj.recipe || null;
    this.partner      = new Partner();
    this.bank_account = new BankAccount();

    switch (withdrawObj.status) {
    case 'required':
      this.class = 'badge badge-warning';
      break;
    case 'approved':
      this.class = 'badge badge-info';
      break;
    case 'processed':
      this.class = 'badge badge-success';
      break;
    case 'rejected':
      this.class = 'badge badge-danger';
      break;
    }

    this.labelStatus = 'global.status.' + this.status;

    if(typeof withdrawObj.partner !== "undefined"){
      this.partner = new Partner(withdrawObj.partner);
    }

    if(typeof withdrawObj.bank_account !== "undefined"){
      this.bank_account = new BankAccount(withdrawObj.bank_account);
    }
  }

  static init(withdrawObj) {
    return new Withdraw(withdrawObj);
  }
}

