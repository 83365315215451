<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import { getBankAccounts } from '@/api/BankAccounts.js'
  import { getWithdrawals, createWithdraw } from '@/api/Withdrawals.js'
  import { Withdraw } from "@/models/Withdraw";
  import { BankAccount } from "../../../models/BankAccount";

  import Multiselect from "vue-multiselect";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Saques",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader, Multiselect },
    data() {
      return {
        title: "Saques",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Saques",
            active: true,
          },
        ],
        list:[],
        bankAccounts: [],
        forms: {
          withdraw: new Withdraw()
        },
        params: {
          page: 1,
          limit: 50
        },
        ui:{
          forms: {
            withdraw: {
              disabled: false
            }
          },
          pagination:{
            current_page: 1,
            last_page: 1,
            per_page: 20,
            total: 0
          }
        }
      };
    },
    computed: {
      hasItems: function () {
        return this.list.length > 0
      }
    },
    created () {
      getBankAccounts().then(response => {
        this.bankAccounts = response.map(item => {
          return new BankAccount(item);
        })
      });
      
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        getWithdrawals(this.params).then(response => {
          vm.params.page = response.current_page
          vm.params.limit = response.per_page

          vm.ui.pagination.current_page = response.current_page
          vm.ui.pagination.last_page = response.last_page
          vm.ui.pagination.per_page = parseInt(response.per_page)
          vm.ui.pagination.total = response.total

          vm.list = response.data.map(item => {
            return new Withdraw(item)
          })
        })
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
      handleOk(bvModalEvt){
        bvModalEvt.preventDefault()

        this.$refs.observer.validate().then(result => {
          if(result){
            this.requestWithdraw()
            this.$bvModal.hide('modal-withdraw')
          }
        });
      },
      requestWithdraw: function () {
        let vm = this

        if(this.ui.forms.withdraw.disabled == false){
          this.ui.forms.withdraw.disabled = true

          vm.$awn.async(
            createWithdraw({
              bank_account_id: this.forms.withdraw.bank_account.id,
              amount: this.forms.withdraw.amount
            }),
            function (result) {
              vm.$awn.success("Dados criados com sucesso.")
              vm.list.unshift(new Withdraw(result))
              vm.ui.forms.create.disabled = false
            },
            function (error) {
              vm.$awn.warning(error || 'Ocorreu um erro ao efetuar a operação. Favor tentar novamente.')
              vm.ui.forms.create.disabled = false
            }
          );
        }
      }
    },
    watch: {
      "ui.pagination.current_page": function (value) {
        this.params.page = value
        this.fetchData()
      }
    }
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped mb-0">
                <thead>
                <tr>
                  <th class="min">#</th>
                  <th>Banco</th>
                  <th>Agência</th>
                  <th>Conta corrente</th>
                  <th class="text-right">Valor</th>
                  <th class="text-center">Status</th>
                  <th>Data</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="!hasItems">
                  <td colspan="100%" class="text-center">
                    <p class="py-4 mb-0">Nenhum resultado encontrado.</p>
                  </td>
                </tr>
                <tr v-else v-for="item in list" v-bind:key="item.id">
                  <th scope="row">
                    {{ item.id }}
                  </th>
                  <td>
                    {{ item.bank_account.bank_id }} - {{ item.bank_account.bank_name }}
                  </td>
                  <td>
                    {{ item.bank_account.agency }}
                  </td>
                  <td>
                    {{ item.bank_account.account }}
                  </td>
                  <td class="text-right">
                    {{ $n(item.amount, 'currency', item.currency) }}
                  </td>
                  <td class="text-center">
                    <span :class="item.class">{{ $t(`global.status.${item.status}`) }}</span>
                  </td>
                  <td class="min">{{ $d(item.created_at, 'long') }}</td>
                </tr>
                </tbody>
              </table>
  
              <b-pagination
                :total-rows="ui.pagination.total"
                :per-page="ui.pagination.per_page"
                v-model="ui.pagination.current_page"
                prev-text="Anterior"
                next-text="Próxima"
                align="center"
                class="mt-3 mb-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  
    <b-button
      variant="primary"
      size="lg"
      class="btn-rounded-fixed"
      pill
      @click="$bvModal.show('modal-withdraw')">
      <i class="bx bx-plus"></i>
    </b-button>
  
    <b-modal
      id="modal-withdraw"
      centered
      title="Solicitar Saque"
      title-class="font-18"
      :cancel-title="'Cancelar'"
      :ok-title="'Salvar'"
      @ok="handleOk"
    >
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(requestWithdraw)"
          class="form-horizontal"
        >
          <validation-provider
            name="Conta Corrente"
            :rules="{ required: true }"
            v-slot="bank_account"
          >
            <b-form-group
              label-cols-sm="3"
              label-cols-lg="3"
              label="Conta Corrente"
              label-for="text"
            >
  
              <multiselect v-model="forms.withdraw.bank_account"
                           track-by="id"
                           label="name"
                           :options="bankAccounts"
                           :allow-empty="false"
                           name="bank_account"
                           placeholder="">
                <template slot="singleLabel" slot-scope="{ option }">
                  <div v-if="option.bank_id">{{ option.bank_id }} - {{ option.bank_name }} <span class="text-muted" style="float: right">  {{ option.agency }} / {{ option.account}}</span></div>
                </template>
                <template slot="option" slot-scope="{ option }">
                  {{ option.bank_id }} - {{ option.bank_name }} <span class="text-muted" style="float: right">  {{ option.agency }} / {{ option.account}}</span>
                </template>
              </multiselect>
              <b-form-invalid-feedback>{{ bank_account.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        
          <validation-provider
            name="Valor"
            :rules="{ required: true}"
            v-slot="amount"
          >
            <b-form-group
              label-cols-sm="3"
              label-cols-lg="3"
              label="Valor"
              label-for="input-user-amount"
            >
              <b-input-group prepend="R$">
                <b-input-money
                  for="amount"
                  v-model="forms.withdraw.amount"
                  id="input-user-amount"
                  class="form-control"
                  name="amount"
                  :auto-decimal-mode="false"
                  :distraction-free="true"
                  :allow-negative="false"
                  :state="getValidationState(amount)"/>
              </b-input-group>
              <b-form-invalid-feedback>{{ amount.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        
          <template v-slot:modal-footer>
            <b-button size="sm" variant="success" type="submit">
              Salvar
            </b-button>
            <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-user')">
              Cancelar
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </Layout>
</template>
